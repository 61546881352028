@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css?family=Poppins:400,500,600')
}
*{
  z-index: 0;
}
body {
  font-family: "Poppins", sans-serif;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.cal {
  background-color: white;
  border-radius: 5px;
  padding: 30px;
}

.tablecutomer {
  border-collapse: collapse;
  width: 100%;
}

.thcustomer, .tdcustomer {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.imgcss {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

/* .dt-button{
  background-color:#0074d9;
  color: white;
  border-radius: 3px;
  height: 30px;
  width: 50px;
  margin: 1px;
  border: none;
} */
.MuiSwitch-root-46{
  color: #0074d9;
}
.MuiSwitch-colorSecondary-52.MuiSwitch-checked-50{
  color: hsla(345, 91%, 70%, 0.965) !important;
}
.add{
  background-color:#0074d9;
  color: white;
  height: 40px;
  width: 40px;
  margin-left:95%;
  border: none;
  /* position: absolute; */
  cursor: pointer;
  border-radius: 50%;
}
